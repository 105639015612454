.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.changed {
  font-style: bold;
  color: red;
}

.unchanged {
  font-style: italic;
}

.problem {
  margin-top: 0px;
  position: relative;
  flex: 0 0 auto;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: grey;
}

.optionsForm {
  border: 2px solid #000; /* You can change the color by replacing #000 with your desired color value */
  padding: 10px; /* Optional: Add padding inside the box */
  display: inline-block;
  /* margin-top: 10px; */
  margin: 10px;
  position: absolute;
  bottom: 44px;
  left: 10px;
}

/* CSS for small screens (e.g., mobile) */
@media (max-width: 1200px) {
  .App {
    display: flex;
    align-content: center;
    flex-direction: column-reverse; /* Stack the items in reverse order */
    min-height: 60vh;
  }
  
  .problem {
    order: 2; /* Move the box below other content */
  }
}

